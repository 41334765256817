<!--订单流水-->
<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="pages" v-loading="loading">

    <table-height-auto>
      <template slot="before">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item>
                <el-input
                  class
                  placeholder="订单编号"
                  prefix-icon="el-icon-search"
                  v-model="searchData.orderId"
                  @keyup.enter.native="search"
                  style="width: 200px"
                >
                  <!-- <el-button slot="append" icon="el-icon-search" @click="search"></el-button> -->
                </el-input>
              </el-form-item>
          <el-form-item>
            <el-select v-model="searchData.timeType"
                       placeholder="状态"
                       style="width: 120px">
              <el-option
                v-for="item in timeTypeArray"
                :disabled="item.disabled"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-date-picker
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="searchData.selectTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="search">
            </el-date-picker>
          </el-form-item>

          <el-form-item>
            <el-select v-model="searchData.status"
                       placeholder="状态"
                       @change="search"
                       style="width: 120px">
              <el-option
                v-for="item in statusTypeArray"
                :disabled="item.disabled"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 订单类型 -->
          <!-- <el-form-item>
            <el-select v-model="searchData.status"
                       placeholder="订单类型"
                       @change="search"
                       style="width: 120px">
              <el-option
                v-for="item in orderTypeArray"
                :disabled="item.disabled"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item>
            <el-select v-model="searchData.businessType"
                       placeholder="状态"
                       @change="search"
                       style="width: 120px">
              <el-option
                v-for="item in businessTypeList"
                :disabled="item.disabled"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="search">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button @click="downloadExcel" icon="el-icon-download">导出</el-button>
          </el-form-item>
        </el-form>
        <div style="text-align: center;color: red; ">
          <span style="padding-right: 35px;" v-if="searchData.selectTime"> {{searchData.selectTime[0]}}至{{searchData.selectTime[1]}} </span>
          <span style="padding-right: 35px;">已结算：{{searchData.settledAmount}} 元</span>
          <span>待结算：{{searchData.waitSettleAmount}} 元</span>
        </div>
      </template>

      <template v-slot:table="row">
        <el-table
          :data="tableData"
          :height="row.heightText"
          v-if="row.height">
          <el-table-column
            label="序号"
            width="60">
            <template slot-scope="scope">{{scope.$index + 1 + (currentPage - 1) * pageSize}}</template>
          </el-table-column>
          <el-table-column
            label="订单类型"
            width="110">
            <template slot-scope="scope">
              {{scope.row.orderType | orderType}}
            </template>
          </el-table-column>
          <el-table-column
            prop="orderId"
            label="订单编号"
            width="210">
          </el-table-column>
          <el-table-column
            prop="settleAmount"
            label="结算金额(含运费)"
            width="150">
          </el-table-column>
          <el-table-column
            prop="settleAmountRefund"
            label="支付后退款"
            width="150">
          </el-table-column>
          <el-table-column
            prop="orderPayTime_text"
            label="支付时间"
            width="200">
          </el-table-column>
          <el-table-column
            prop="orderPayTime_text"
            label="订单支付时间"
            width="200">
          </el-table-column>
          <el-table-column
            prop="settleTime_text"
            label="结算时间"
            width="200">
          </el-table-column>
          <el-table-column
            label="状态"
            width="140">
            <template slot-scope="scope">
              {{settlementStatus[scope.row.status]}}
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="showDetail(scope.row.orderId)">详情</el-button>
            </template>
          </el-table-column>

        </el-table>
      </template>

      <template slot="after">
        <div class="paging-row">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 15, 20]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalListNum">
          </el-pagination>
        </div>
      </template>
    </table-height-auto>
  </div>
</template>

<script>
  import {URL} from '../../../config'
  import {disposeSearchData, getNextDayTimestamp, isEmpty, parseTime, parseTimeArray} from '../../../assets/js/utils'
  import {statusType, statusTypeArray} from '../../../assets/js/status.js'
  import {plusDownloadExcelTask} from '../../../store/excel'

  export default {
    name: 'getSupplierSettleList',
    filters: {
        orderType(num) {
            switch (num) {
                case 0:
                    return '普通订单';
                case 1:
                    return '送礼订单';
                case 2:
                    return '盲盒订单';
                case 3:
                    return '收礼订单';
                case 4:
                    return '农场租赁订单';
                case 5:
                    return '农场提菜订单';
                case 6:
                    return '农场服务订单';
            }
        }
    },
    data() {
      return {
        showStartEndTime: null,
        loading: false,
        tableData: [],
        searchData: {
          selectTime: [],
          status: 0,
          businessType: '',
          timeType:'1',
          orderId: '',
          settledAmount: '0',
          waitSettleAmount: '0',
        },
        settlementStatus: {
          1: '待结算',
          2: '已结算'
        },
        orderTypeArray: [
          {id:'',name:'全部'},
          {id:'1',name:'零售订单'},
          {id:'2',name:'包装订单'},
          {id:'3',name:'代运营订单'},
          {id:'4',name:'产品拍图订单'},
        ],
        timeTypeArray:[
          {id:'1',name:'支付时间'},
          {id:'2',name:'结算时间'}
        ],
        businessTypeList:[
          {id:'',name:'全部订单'},
          {id:'1',name:'普通订单'},
          // {id:'2',name:'批发订单'},
        ],
        statusType,
        statusTypeArray,
        /* 分页参数 */
        pageSize: 10,
        totalListNum: 10,
        currentPage: 1,
        showSettleDetailVisible:false,
        settleDetail:{},
      }
    },
    created() {
      let endText = new Date();
      let startText = new Date();
      startText.setTime(startText.getTime() - 3600 * 1000 * 24 * 30);
      this.searchData.selectTime = [parseTime(startText), parseTime(endText)];
      this.showStartEndTime = this.searchData.selectTime;

      this.getList();
      this.getStatistics();
    },
    methods: {
      /**
       * 拼接搜索参数
       */
      jointSearchData() {
        let data = {
          pageNo: this.currentPage,
          pageSize: this.pageSize,
        };
        data = Object.assign(data, this.searchData);
        disposeSearchData(data);
        let selectTime = data.selectTime;
        let startTime = 0;
        let endTime = 0;

        if (!isEmpty(selectTime)) {
          let times = selectTime.map(res => new Date(res).getTime())
          startTime = times[0];
          endTime = getNextDayTimestamp(times[1]);
        }

        data.startTime = startTime;
        data.endTime = endTime;

        delete data.selectTime;
        return data;
      },
      /**
       * 搜索
       */
      search() {
        this.currentPage = 1;
        this.getList();
        this.getStatistics();
        this.showStartEndTime = this.searchData.selectTime;
      },
      getList() {
        return new Promise((resolve, reject) => {
          let data = this.jointSearchData();
          this.loading = true;
          this.axios.get(URL.suppWallet.getSupplierSettleList, {params: data}).then(res => {
            let list = res.data.records || [];
            parseTimeArray(list, ['orderPayTime','settleTime']);
            this.tableData = list;
            this.totalListNum = Number(res.data.total);
            resolve(res);
          }).catch(res => {
            this.tableData = [];
          }).finally(res => {
            this.loading = false;
          })
        })
      },
      getStatistics() {
        return new Promise((resolve, reject) => {
          let data = this.jointSearchData();
          this.loading = true;
          this.axios.get(URL.suppWallet.getSupplierSettleSum, {params: data}).then(res => {
            let list = res.data || [];

            this.searchData.settledAmount = res.data.settledAmount;
            this.searchData.waitSettleAmount = res.data.waitSettleAmount;
            resolve(res);
          }).catch(res => {
            this.tableData = [];
          }).finally(res => {
            this.loading = false;
          })
        })
      },
      /* 分页 */
      handleSizeChange(val) {
        this.pageSize = val;
        this.currentPage = 1;
        this.getList()
      },
      handleCurrentChange(val) {
        this.currentPage = val;
        this.getList();
      },
      /**
       * 导出excel
       */
      downloadExcel() {
        let searchData = this.jointSearchData();
        let data = {
          name: '供应商订单流水明细报表',
          params: {
            excelType: 1005,
            excelParameterJson: JSON.stringify(searchData),
          },
        };
        plusDownloadExcelTask.call(this, data);
      },
      showDetail(orderId){
        this.$router.push({
          path:'/supplierSettleDetail',
          query:{
            orderId:orderId,
          }
        });
      },
    }
  }

</script>

<style>
</style>
